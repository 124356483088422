$common-black:#000000;
$common-black:#000000;
$common-black:#000000;
$common-white:#ffffff;
$common-gray:#303030;
$common-lightgray:#828282;
$common-error:#dc3545;
$full-width:100%;
$no-padding:0 0 0 0;

html, body{
    height:100%;
}

.container{
    &.multiSlide{
        padding:0px;
    }
}

.invert{
    img{
        -webkit-filter: invert(100%) !important;
    }
}

.fullScreenGallery{
    .slick-dots{
        bottom:25px;
    }
    .slick-prev{
        z-index: 999;
        left:40px !important;
     }
    .slick-next{
        z-index: 999;
        right:40px !important;
    }
}


.grayscale {
    -moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
    filter: gray;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
}

.modal-header, .modal-body{
    color:$common-black;
}

.modal-body{
    font-family: "Visby CF", sans-serif;
    img{
        margin-bottom:1rem;
    }
}

body{
    overflow: hidden;
    overflow-y: auto;
}

.section, .fullwidth, .container{
    opacity:0;
}

:root {
    --mainColor: #828282;
}

.careersImages{
    .entry{
        padding:10px;
    }
}

.whiteTheme{
    div{
        color:$common-white !important;
    }
}

.header{
    &.menuFixed{
        .header__menuTriggerSvg--open, .header__menuTriggerSvg--close{
            position:fixed;
        }
        .header__menuTriggerText--open{
            position:relative;
            top:5px;
            left:40px;
        }
        .header__menuTriggerText--close{
            position:fixed;
            top:39px;
            left:70px;
        }
    }
}

.upcomingEventEntry{
    .fader{
        position:relative;
        overflow: hidden;
        height:175px;
        img{
            position:absolute;
            top:0px;
            left:0px;
        }
    }
}



.faqgroup{
    .answer{
        padding:0.5rem;
        display:none;
    }
    .question{
        border:2px solid $common-black;
        padding:0.5rem;
        font-weight: 700;
        font-family: "Visby CF Bold", sans-serif;
        text-transform: uppercase;
        margin-bottom:0.5rem;
        .fa-plus{
            display:block;
        }
        .fa-minus{
            display:none;
        }

        .fas{
            float:right;
            margin-top: 5px;
        }
        &:hover{
            cursor:pointer;
            background: $common-black;
            color: $common-white;
        }
        &.clicked{
            background: $common-black;
            color: $common-white;
            .fa-plus{
                display:none;
            }
            .fa-minus{
                display:block;
            }

        }
    }
    margin-bottom:2rem;
}

.nestedCalendar{
    float:left;
    .wrap{
        min-height:515px;
    }
}

.galleryrow{
    flex-wrap:none;
    display:block;
    overflow:hidden;
}
.galleryentry{
    float:left;
    height:304px;
    margin-bottom:1rem !important;
}

.img-responsive{
        width:100%;
        height:auto;
    }

html, body{
    height:100%;
}

body{
    font-family: 'Visby CF Bold', sans-serif;
    background:$common-white;
}

.alert{
    font-size:0.8rem;
    font-family: "Visby CF", sans-serif;
}

.hidden{
  display:none;
}

.btn, input, select, textarea{
    outline:none !important;
    box-shadow: none !important;
}

.artists{
    padding-top:4rem;
    padding-bottom:4rem;
}

.bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.menuOpen{
    .arrowDown{
        display:none !important;
    }
}

.fullscreenHeader{
    height:100%;
    min-height:930px;
    min-width:100%;
    background-size: cover;
    img.arrowDown{
        position:absolute;
        bottom:3rem;
        left:50%;
        width:50px;
        margin-left:-25px;
        opacity:0.6;
        z-index:9999;
    }

    &.smallwhite{
        img.arrowDown{
            display:none;
        }
        height:130px;
        min-height:auto;
        .header__menuTriggerText--open{color:$common-black !important;}
        .header__menuList--topNav a{
            color:$common-black;
            &:hover{
                color:$common-white;
            }
        }
    }

    &.about{
        background:url(../images/image-header-about.jpg) center center no-repeat $common-gray;
        background-size: cover;
    }

    &.gallery{
        background:url(../images/image-header-bg.jpg) center center no-repeat $common-gray;
        background-size: cover;
    }

    .header-event-name{
        height:910px;
        text-align:center;
        font-size:3.5rem;
        line-height:5rem;
        font-weight:bold;
        text-transform: uppercase;
        div{
            display:block;
        }
        .header-event-name-name{
            font-size: 6.5rem;
            font-weight: 700;
            &.singlepage{
                font-size:12rem;
                &.smaller{
                    font-size:6rem;
                }
            }
        }
        .header-event-name-links{
            a{
                width:185px;
            }
        }
    }
}
.btn{
    &.wynwood{
        font-family: 'Visby CF Bold', sans-serif;
        border-radius:0px;
        border:2px solid $common-black;
        background:$common-black;
        color:$common-white;
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: bold;
        padding:.55rem 3rem;
        &.bordered {
            border: 2px solid $common-white;
            &:hover, &:focus, &:active {
                background: $common-white;
                color: $common-black;
            }
            &.soldout{
                border: 2px solid $common-white;
            }
        }
        &:hover, &:focus, &:active{
            color:$common-black;
            background: $common-white;
            border-color: $common-black;
        }
        &.soldout{
            color:$common-error;
            &:hover, &:focus, &:active{
                color:$common-error !important;
                background:$common-black;
            }
        }

        &.small{
            padding:.25rem 1rem;
            margin-left:1rem;
            margin-top:-7px;
        }

        &.tiny{
            padding:.2rem 0.5rem;
            margin-left:0px !important;
            margin-top:-7px;
            font-size:1rem !important;
            &.moveup{
                margin-top:-10px;
            }
        }

        &.white{
            color:$common-black;
            background: $common-white;
            border-color: $common-white;
            &:hover, &:focus, &:active{
                color:$common-white;
                background: $common-black;
                border-color: $common-white;
            }
            &.bordered{
                border:2px solid $common-black;
                &:hover, &:focus, &:active{
                    background:$common-black;
                    color:$common-white;
                }

                &.small{
                    padding:.25rem 1rem;
                    margin-left:1rem;
                    margin-top:-7px;
                }
           }
            &.tiny{
                padding:.15rem 0.5em;
                margin-left:0rem;
                width:100%;
                margin-top:10px;
                font-size:1rem;
                font-family: 'Visby CF', sans-serif;
            }
        }

    }
}

.twolinks{
    overflow:hidden;
    a{
        font-size:1rem !important;
        width:48.5%;
        padding:.55rem 0.5em !important;
        &:nth-of-type(2){
            float:right;
            margin-left:1.5%;
        }
        &:nth-of-type(1){
            float:left;
            margin-right:1.5%;
        }

    }
}

.section{
    margin:4rem auto;
    &.plainpage{
        min-height:545px;
    }
    &.news{
        margin:7rem auto;
    }
    &.venuegallery{
        margin:-2rem auto 2rem auto;
    }
    &.singleGallery{
        margin:0 auto;
        padding:3rem 0px !important;
    }
}

.upcomingevents {
    .toggler {
        display:none;
    }
}
        .eventscalendar{
    .wrap{
        background:$common-black;
        height:100%;
        padding:40px;
        h3{
            color:$common-white;
            text-align:center;
            font-family: "Visby CF Bold", sans-serif;
            font-size:3rem;
        }
        h5{
            color:$common-white;
            text-align:center;
            font-family: "Visby CF Bold", sans-serif;
            font-size:1.5rem;
            margin-top:-1rem;
        }
        .datepicker-inline{
            margin:1rem auto;
            width:100%;
            *{
                color:$common-white;
              text-transform: uppercase;
            }
            .day{
                color:$common-lightgray !important;
                &:hover{
                    background:$common-gray;
                }
                &.activeClass{
                  color:$common-white !important;
                }
                &.active{
                    background:none;
                    border:1px solid #f7ecb5;
                    border-radius: 0px;
                    color:$common-white !important;
                }
                &.today{
                    background:$common-lightgray;
                    color:$common-white !important;
                }
            }
            th{
                padding-top:10px;
                padding-bottom:10px;

                &:hover{
                    background:$common-gray;
                }
            }
            .month{
                &:hover, &.focused{
                    background:$common-gray;
                }
            }
            .dow{
                display:none;
            }
            tbody{
                    margin-top:10px;
            }
        }
        .datepicker table{
            width:100%;
        }
    }
}

.footer{
       margin:0px !important;
    img{
        margin-top:10px;
    }
    .links{
        font-size:1.2rem;
        a{
            color:$common-white;
            &:hover{
                color:$common-lightgray;
                text-decoration: none;
            }
        }
    }
    .address{
        font-family: "Visby CF", sans-serif;
        font-size: 1.1rem;
        a{
            color:$common-white;
            &:hover{
                color:$common-lightgray;
                text-decoration: none;
            }
        }
    }
    .socials{
        margin-top:12px;
    }

    .mailinglist{
        .links{
            font-family: "Visby CF", sans-serif;
            margin-top:1rem;
        }
    }
}

.bigmargin{
    margin:5rem 0 5rem 0;
}

.findus{
    margin:12rem auto;
}

.upcomingevents{

}

form#requestForm, form#workForm{
    input[type=submit]{
        margin-top:1rem;
    }
}

.blackcol{
    background:$common-black;
    color:$common-white;
    padding:2rem 2rem 2rem 2rem;
    input,textarea, select, .input-group-text, .custom-file-label{
        border-radius: 0px;
    }
    &.biggerH3{
        h3 {
            font-size: 2.4rem !important;
        }
    }
}

.quote{
    background:url("../images/image-quote-bg.jpg") center center no-repeat;
    background-size: cover;
    height:540px;
    .quoterow{
        height:540px;
        .col{
            text-align:center;
            color:$common-white;
            blockquote{
                font-family: "Visby CF Bold", sans-serif;
                font-size:4rem;
                color: $common-white;
                font-size: 700;
            }
            span{
                display:block;
                font-size:2.4rem;
                font-family: "Visby CF", sans-serif;
                margin-top:-2rem;
            }
        }
    }
}

.upcomingEventEntry{
    text-transform: uppercase;
    font-size:1.5rem;
    line-height:1.5rem;
    margin-bottom:2rem;
    &:hover{
        .ue-date, .ue-eventname, .ue-artistname{
            color:$common-lightgray !important;
        }
        img{
          //  opacity:0.8;
        }
    }

    &.artistEntry{
        &:hover{
            .ue-date, .ue-eventname, .ue-artistname{
                color:$common-white !important;
            }
            a{
                color:$common-black !important;
            }
            a.readmore{
                color:$common-lightgray !important;
                &:hover{
                    color:$common-white !important;
                }
            }
            img{
                opacity:1;
            }
        }
    }

    a{
        color:$common-black;

        &:hover{
            .smallCover{
                opacity:0.8 !important;
            }
            text-decoration: none;
            color:$common-lightgray !important;
        }
    }

    &.artistEntry{
        color:$common-white;
        p{
            text-transform: none;
        }
        a.readmore{
            color:$common-lightgray;
        }
        .socials{
            margin-top:-0.5rem;
            a{
                margin-right:0.5rem;
            }
        }
        img{
            border:2px solid $common-white;
        }
    }

    img{
        margin-bottom:1rem;
        height:175px;
    }
    .smallCover{
        margin-bottom:1rem;
        height:195px;
    }

    .ue-eventname, .ue-artistname{
        font-family: 'Visby CF Demi', sans-serif;
    }
    .ue-eventname{
        font-size:1.5rem;
        margin-bottom:1.2rem;
    }

    .ue-artistname{
        font-size:2rem;
        line-height:2rem;
        min-height:100px;
    }

    a{
        margin-top:1rem;
    }
}

.eventsgrid {
    .ue-eventname{
        //min-height:50px;
    }
}


.upcomingEventEntry .newsgrid .ue-eventname{min-height:auto !important;}

.careersImages .col-3{
    padding-bottom:15px;
    padding-top:15px;
}

.maplinks{
    margin:1.7em 0px;
    a{
        margin-left:0rem;
    }
    &.contactones{
        margin-top:0rem;
        a.small{
            margin-left:0px !important;
        }
    }
}

.textarea{
    color:$common-black;
    font-family: 'Visby CF', sans-serif;
    font-size: 1.2rem;
    h4{
        font-size: 2rem;
    }
    h3, h1{
        font-weight: 700;
        text-transform: uppercase;
        font-family: 'Visby CF Bold', sans-serif;
        font-size: 2.4rem;
        margin-bottom:2rem;
        span{
            color:$common-lightgray;
        }
    }
    &.closeup{
        h3{
            margin-bottom:1rem;
        }
    }
    &.plainpage{
        min-height:545px;
    }
    &.newsbody{
        h5{
            font-size:1.5rem;
            margin-bottom:1.5rem;
            margin-top:-1rem;
            text-transform: uppercase;
        }
        img.newsImage{
            float:right;
            width:50%;
            margin:0 0 1.5rem 2rem;
        }
    }

    &.justaheader{
        h3{
            margin-bottom:3rem;
        }
    }

    a{
        color:$common-gray;
        &:hover{
            color:$common-black;
        }
    }
}

.fullwidth{
    width:$full-width;
    padding:1rem 0;
    margin:4rem auto;
    &.black{
        background:$common-black;
        color:$common-white;
        h3{
            font-size:3rem;
        }
        &.hashevents{
            h3{
                font-size:7.5rem;
            }
        }
        &.wannabook{
            h3{
                font-size:5.1rem;
            }
        }
    }
    &.nomargin{
        margin:0 auto;
    }
    .eventinfo{
        .row{
            padding-top:4rem;
            padding-bottom:4rem;
        }
            a.btn{
                margin:0 0.5rem;
                &:nth-of-type(1){
                    margin-left:0px;
                }
            }
           .spec{
            font-size:3rem;
            line-height:2.7rem;
            font-weight: 700;
            margin-bottom:2rem;
            text-transform: uppercase;
               &.artistName{
                   line-height: 3rem;
               }
            span{
                display: block;
                font-family: "Visby CF",sans-serif;
                font-weight: normal;
                text-transform: uppercase;
                font-size: 1.6rem;
            }
        }
    }
}

.slick-prev::before, .slick-next::before{
    color:$common-black !important;
    content: '' !important;
}

.slick-prev{
    left:-40px !important;
    width:25px !important;
    height:50px !important;
    background:url("../images/image-arrow-left.png") center center no-repeat !important;
}

.slick-next{
    right:-40px !important;
    width:25px !important;
    height:50px !important;
    background:url("../images/image-arrow-right.png") center center no-repeat !important;
}

.singleGallery{
    .slick-prev{
        background:url("../images/image-arrow-left-white.png") center center no-repeat !important;
    }
    .slick-next{
        background:url("../images/image-arrow-right-white.png") center center no-repeat !important;
    }

    .slick-prev::before, .slick-next::before{
        color: $common-white !important;
    }
    .slick-dots li button::before{
        color:$common-white !important;
    }
}


.galleryItem{
    .cover{
        height:720px;
        position: relative;
        a{
            position:absolute;
            bottom:20px;
            right:20px;
        }
        div.info{
            position:absolute;
            bottom:5px;
            left:20px;
            color:$common-white;
            text-transform: uppercase;
            font-size:1.5rem;
            line-height:1.5rem;
            font-family: 'Visby CF', sans-serif;
            h4{
                font-family: 'Visby CF Demi', sans-serif;
                font-weight: 500;
            }
        }

      div.share{
        position:absolute;
        bottom:5px;
        left:60px;
        color:$common-white;
        text-transform: uppercase;
        font-size:1.5rem;
        line-height:1.5rem;
        font-family: 'Visby CF', sans-serif;
         a{
             display:inline-block;
             text-align:center;
             position:relative;
             width:24px;
             height:24px;
             font-size:1rem;
             background:$common-white;
             color:$common-black;
             border-radius: 12px;
             &:hover{
                 color:$common-white;
                 background: $common-black;
             }
         }
        h4{
          font-family: 'Visby CF Demi', sans-serif;
          font-weight: 500;
            font-size:1rem;
            margin-bottom:20px;
            margin-left:-20px;
        }
      }

    }
}

.newsgrid{
    p{
        text-transform: none;
    }
    a{
        color:$common-black;
        &:hover{
            color:$common-lightgray;
            text-decoration: none;
            img{
                opacity:0.8;
            }
        }
    }
}

.findus{
    h3{
      margin-bottom:0px;
    }
    h4{
        text-transform: uppercase;
        font-family: 'Visby CF Demi', sans-serif;
    }
    .col-12{
        position:relative;
        img{
            position:absolute;
            right:0px;
            top:-268px;
        }
    }
}

.partners{
    background:$common-gray;
    color:$common-white;
    padding:8rem 0px;
    margin-bottom:0px;
    h3{
        color:$common-white;
    }
    img{
        width:100%;
        max-width:715px;
    }
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled],
html input[disabled] {
    cursor: not-allowed
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0
}

button {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    padding: 0;
    border: 0;
    border-radius: 0;
    line-height: 1
}


.lazyLoadFrame.loaded img {
    opacity: 1
}

.lazyLoadFrame img {
    opacity: 0;
    transition: opacity .3s ease-in-out
}

.JS--lazyLoadFromFlickity,
.JS-lazyLoad {
    opacity: 0;
    transition: opacity .5s ease-out
}

.JS--lazyLoadFromFlickity.loaded,
.JS-lazyLoad.loaded {
    opacity: 1
}

.scrollItem {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
    transition: all .5s ease-out
}

.scrollItem.revealed {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }
    99% {
        opacity: 1
    }
    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }
    99% {
        opacity: 1
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1
    }
    99% {
        opacity: 0
    }
    to {
        display: none
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }
    99% {
        opacity: 0
    }
    to {
        display: none
    }
}

@-webkit-keyframes fadeInAndUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }
    99% {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
    to {
        opacity: 1
    }
}

@keyframes fadeInAndUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }
    99% {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes growIn {
    0% {
        -webkit-transform: scale3d(.7, .7, 1);
        transform: scale3d(.7, .7, 1)
    }
    1% {
        opacity: 1
    }
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-font-smoothing: antialiased;
        opacity: 1
    }
}

@keyframes growIn {
    0% {
        -webkit-transform: scale3d(.7, .7, 1);
        transform: scale3d(.7, .7, 1)
    }
    1% {
        opacity: 1
    }
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-font-smoothing: antialiased;
        opacity: 1
    }
}

@-webkit-keyframes moveclouds {
    0% {
        -webkit-transform: translateY(100vh);
        transform: translateY(100vh)
    }
    to {
        -webkit-transform: translateY(-200%);
        transform: translateY(-200%)
    }
}

@keyframes moveclouds {
    0% {
        -webkit-transform: translateY(100vh);
        transform: translateY(100vh)
    }
    to {
        -webkit-transform: translateY(-200%);
        transform: translateY(-200%)
    }
}

@-webkit-keyframes sideWays {
    0% {
        -webkit-transform: translateX(-20%);
        transform: translateX(-20%)
    }
    50% {
        -webkit-transform: translateX(30%);
        transform: translateX(30%)
    }
    to {
        -webkit-transform: translateX(-20%);
        transform: translateX(-20%)
    }
}

@keyframes sideWays {
    0% {
        -webkit-transform: translateX(-20%);
        transform: translateX(-20%)
    }
    50% {
        -webkit-transform: translateX(30%);
        transform: translateX(30%)
    }
    to {
        -webkit-transform: translateX(-20%);
        transform: translateX(-20%)
    }
}

.header {
    color: $common-white;;
    padding: 2.125rem 0 .5rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 25;
    text-align: center;
    .row{
        margin:0px;
    }
}

@media screen and (min-width:40em) {
    .header {
        padding: 2.125rem 0 .75rem
    }
}

@media screen and (min-width:64em) {
    .header {

    }
}


.header__logoWrap {
    position: relative;
    z-index: 15;
    display: inline-block;
    line-height: 1;
    width:205px;
    img{
        width:100%;
        height:auto;
        &.white{
            //display:none;
            transform:translate(9999px);
            opacity: 0;
            width:auto !important;
            transition: opacity 0.8s linear;
        }
    }
}

@media screen and (min-width:64em) {
    .header__logoWrap {
        top: 0rem
    }
}

.row.header__menusRow {
    max-width: 45rem;
    flex-grow: 2;
    margin-top: 0
}

@media screen and (min-width:40em) {
    .row.header__menusRow {
        margin-top: 2rem
    }
}

@media screen and (min-width:64em) {
    .row.header__menusRow {
        margin-top: 4rem
    }
}

.header__menuWrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $common-black;
    padding: 4rem 2rem 2rem;
    pointer-events: none;
//    display: none;
    transform:translate(9999px);
    opacity: 0;
    flex-direction: column;
    justify-content: center;
    text-align: left;

    &.menuOpened{
        display:flex;
        transition: opacity 0.3s linear;
        transform:translate(0);
        opacity: 1;
        z-index: 10;
        pointer-events: auto;
    }
}

.header__menuWrap .menu-main-menu-container {
    opacity: 0;
    transition: opacity .6s ease-in-out
}

.header__menuWrap li.has-dropdown {
    position: relative;
    display: inline-block
}

.header__menuWrapInner {
    flex: 1
}

@media screen and (min-width:40em) {
    .header__menuWrapInner .columns--flex {
        justify-content: flex-end;
        align-items: flex-end
    }
}

.header__menuList {
    list-style: none;
    margin: auto
}

.header__menuList li {
    display: block
}

.header__menuList li.current-menu-item a {
    font-weight: 600;
}

.header__menuList li a {
    border: 2px solid $common-black;
    text-transform: uppercase;

    &:hover{
        text-decoration: none;
    }
}

.menu-item-object-custom{
    a{
        &:hover{
            background:$common-black;
        }
    }
}

.noBanner .header__menuList li a {
    border-color: $common-black;
}

.noBanner>.menuOpen .header__menuList li a {
    border-color: $common-white;
}

.header__menuList a {
    padding: .625rem;
    display: block;
    color: $common-white;
}

.noBanner .header__menuList a {
    color: $common-black;
}

.noBanner>.menuOpen .header__menuList a {
    color: $common-white;
}

@media screen and (min-width:40em) {
    .header__menuList a {
        padding: 1rem
    }
}

.header__menuList a:active,
.header__menuList a:focus,
.header__menuList a:hover {
    text-decoration: underline
}

.header__menuList--topNav {
    display: none
}

@media screen and (min-width:64em) {
    .header__menuList--topNav {
        display: inline-block;
        position: absolute;
        top: 0rem;
        right: 2rem
    }
}

.header__menuList--topNav li {
    display: inline-block
}

.header__menuList--topNav a {
    padding: .5rem 1rem;
    font-size: 1.3rem;
    font-weight: 700
}

.header__menuList--primary{
    margin-left:-20px;
}

.header__menuList--primary a {
    font-size: 1.5rem;
    font-weight: 700;
    color:$common-lightgray;
    text-decoration: none !important;
    background:
            linear-gradient(
                            to bottom, var(--mainColor) 0%,
                            var(--mainColor) 100%
            );
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 0px 0px;
    transition: background-size .5s;

    &:hover{
        color:$common-white;
        background-size: 2px 90px !important;
        text-decoration: none !important;
    }
}

@media screen and (min-width:40em) {
    .header__menuList--primary a {
        font-size: 2.25rem
    }
}

.header__menuList--secondary {
    display: block;
    margin-left: -20px;
    margin-top: -100px;
}

@media screen and (min-width:40em) {
    .header__menuList--secondary {
        //margin-top: .5rem;
        display: inline-block;
        margin-top: 0px;
    }
}

.header__menuList--secondary a {
    font-size: 1.5rem;
    font-weight:700;
    background:
            linear-gradient(
                            to bottom, var(--mainColor) 0%,
                            var(--mainColor) 100%
            );
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 0px 0px;
    transition: background-size .5s;
    color:$common-lightgray;
    text-decoration: none !important;
    &:hover{
        color:$common-white;
        background-size: 2px 90px !important;
        text-decoration: none !important;
    }
}

.form-control{
    &.error{
        border:1px solid $common-error;
        background:#f6dddd;
    }
}

#datePicker{
    border:1px solid $common-black;
    &.error{
        border:1px solid $common-error;
    }
}

.custom-select{
    &.error{
        border:1px solid $common-error !important;
        background-color:#f6dddd !important;
    }
}

.mailinglist{
    P{
        text-transform: uppercase;
        font-size:1.5rem;
    }
    form{
        border:1px solid $common-lightgray;
        width:100%;
        display:block;
        padding:0.4rem 0.9rem;
        overflow: hidden;
        &.error{
            border:1px solid $common-error;
        }
        input[type=text]{
            float:left;
            width:70%;
            padding:0.3rem;
            background:transparent;
            font-family: 'Visby CF', sans-serif;
            color:$common-white;
            border:none;
        }
        input[type=submit] {
            float:left;
            width:30%;
            background:transparent;
            font-family: 'Visby CF', sans-serif;
            color:$common-white;
            border-color: $common-black;
            border-left:2px solid $common-white;
            border-radius: 0px;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
}

.socials{
    margin-top:61px;
    a{
        display:inline-block;
        background:$common-white;
        color:$common-black;
        text-align: center;
        font-size:1.2rem;
        vertical-align: middle;
        width:30px;
        height: 30px;
        border-radius: 15px;
        line-height:2rem;
        margin-right:5px;
        &:hover{
            color:$common-white;
            background:$common-lightgray;
        }
    }
}

@media screen and (min-width:40em) {
    .header__menuList--secondary a{
        font-size: 2.25rem
    }
}

.header__menuTrigger {
    position: absolute;
    z-index: 15;
    top: 0rem;
    left: 1rem
}

@media screen and (min-width:40em) {
    .header__menuTrigger {
        top: 0 rem
    }
}

@media screen and (min-width:64em) {
    .header__menuTrigger {
        top: 0rem;
        left: 2rem
    }
}

.header__menuTrigger:active,
.header__menuTrigger:focus {
    outline: none
}

.header__menuTriggerSvg {
    height: 2.125rem
}

.header__menuTriggerSvg--close {
    display: none;
    stroke: $common-white !important;
    stroke-width: 2px;
}

.header__menuTriggerSvgLine {
    stroke: $common-black;
    stroke-width: 2px
}

.noBanner .header__menuTriggerSvgLine {
    stroke: $common-white;
}

.noBanner>.menuOpen .header__menuTriggerSvgLine {
    stroke: $common-white
}

.header__menuTriggerText {
    display: none
}

.grayscale, .whiteTheme{
    .header__menuTriggerSvg--close, .header__menuTriggerSvgLine{
        stroke: $common-white !important;
    }
}

@media screen and (min-width:40em) {
    .header__menuTriggerText {
        color: $common-white;
        font-size: 1.5rem;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 0 0 .875rem;
        display: inline-block;
        position: relative;
        top: 4px
    }
}

.noBanner .header__menuTriggerText {
    color: $common-black;
}

.noBanner>.menuOpen .header__menuTriggerText {
    color: $common-white
}

@media screen and (min-width:64em) {
    .header__menuTriggerText {
        vertical-align: text-top;
        font-size: 1.5rem;
        top: -3px;
    }
}

.header__menuTriggerText--close {
    display: none
}

.buyTickets {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5000;
    text-align: center;
    padding: 1rem;
    display: inline-block;
    font-size: .8125rem;
    transition: background-color .3s ease-in-out
}

@media screen and (min-width:64em) {
    .buyTickets {
        padding: .625rem 1rem;
        position: static;
        text-align: right;
        margin-left: 2rem;
        border-radius: 1.25rem
    }
}

.buyTickets--desktop {
    display: none
}

@media screen and (min-width:64em) {
    .buyTickets--desktop {
        display: inline-block
    }
}

.buyTickets--mobile {
    z-index: 100
}

@media screen and (min-width:64em) {
    .buyTickets--mobile {
        display: none
    }
}

body.menuOpen {
    overflow: hidden
}

body.menuOpen .header__menuTriggerText--open {
    display: none
}

@media screen and (min-width:40em) {
    body.menuOpen .header__menuTriggerText--close {
        display: inline-block
    }
    body.menuOpen .header .header__logoWrap img.white{
        //display: block !important;
        transform:translate(-50px) !important;
        opacity: 1 !important;
        transition: opacity 0.8s linear !important;
    }

}

body.menuOpen .header .header__logoWrap{
    position:absolute;
    right:0rem;
    top:0rem;
}

/*
body.menuOpen .header__menuWrap {
    display: flex;
    z-index: 10;
    pointer-events: auto;
}

 */

@media screen and (max-height:900px) {
    body.menuOpen .header__menuWrap {
        overflow: scroll;
        display: block
    }
    body.menuOpen .header__menuWrap .header__menuWrapInner {
        min-height: 480px;
        padding-top: 0rem
    }
}

body.menuOpen .header__menuWrapInner {
    flex: 1;
    display: flex;
    flex-direction: column
}

body.menuOpen .header__menuList>li {
    opacity: 0;
    -webkit-animation: fadeInAndUp .3s ease-out;
    animation: fadeInAndUp .3s ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

body.menuOpen .header__menuList>li:first-child {
    -webkit-animation-delay: .1s;
    animation-delay: .1s
}

body.menuOpen .header__menuList>li:nth-child(2) {
    -webkit-animation-delay: .2s;
    animation-delay: .2s
}

body.menuOpen .header__menuList>li:nth-child(3) {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

body.menuOpen .header__menuList>li:nth-child(4) {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

body.menuOpen .header__menuList>li:nth-child(5) {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

body.menuOpen .header__menuList>li:nth-child(6) {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}

body.menuOpen .header__menuList>li:nth-child(7) {
    -webkit-animation-delay: .7s;
    animation-delay: .7s
}

body.menuOpen .header__menuList>li:nth-child(8) {
    -webkit-animation-delay: .8s;
    animation-delay: .8s
}

body.menuOpen .header__menuList>li:nth-child(9) {
    -webkit-animation-delay: .9s;
    animation-delay: .9s
}

body.menuOpen .header__menuList>li:nth-child(10) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

body.menuOpen .header__socialLinks--mobile {
    opacity: 0;
    -webkit-animation: fadeInAndUp .3s ease-out 1s;
    animation: fadeInAndUp .3s ease-out 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

body.menuOpen .menu-main-menu-container {
    opacity: 1;
    transition: opacity .6s ease-in-out
}

body.menuOpen .header__menuTriggerSvg--open {
    display: none
}

body.menuOpen .header__menuTriggerSvg--close {
    display: inline-block;
    .header__menuTriggerSvgLine{
        stroke: $common-white !important;
    }
}

body.menuOpen .header .header__logoWrap img.black{
    display: none;
}

body.menuOpen .header .header__logoWrap img.white{
    //display: none;
    transform:translate(9999px);
    opacity: 0;
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {

    div.share {
        bottom: 54px !important;
        left: 60px;
    }

    .upcomingEventEntry .ue-artistname{
        font-size:1.5rem !important;
        line-height:1.5rem !important;
    }

    .smallerMobile{
        font-size: 8rem !important;
    }
    .maplinks{
        a{
            width:100%;
            margin-left:0px !important;
            margin-bottom:1rem;
        }
    }


    .careersImages{
        .entry{
            padding:5px;
        }
    }

    .eventinfo{
        a.btn{
            margin:0px 0px 10px 0px !important;
            width:80%;
        }
    }

    .mobilehide{display:none !important;}
    .eventscalendar {
        .wrap {
            padding:10px;
        }
    }

    .findus{
        .col-12{
            img{
                width:500px;
                top:-210px;
            }
        }
    }

    .fullscreenHeader{
        .header-event-name{
            height:100%;
        }
    }

    .upcomingEventEntry{

        img, .fader{
            height:145px;
        }
        .smallCover{
            margin-bottom:1rem;
            height:195px;
        }

    }

    .footer{
        .socials{
            margin-top:0px;
            a{
                margin-bottom:0.5rem;
                &:last-child{
                    margin:0px;
                }
            }
        }
    }

    .fullwidth{
        &.black{
           &.hashevents{
                h3{
                    font-size:4.2rem;
                }
            }
            &.wannabook{
                h3{
                    font-size:2.65rem;
                }
            }

        }
    }


}


@media (max-width:1199px){
    body.menuOpen .header .header__logoWrap{
           right:-4rem;
    }
    .bigmargin{
        margin:6rem 0 6rem 0;
    }

    .upcomingEventEntry{
        margin-bottom:2rem;
        img, .fader{
            height:165px;
        }
        .smallCover{
            margin-bottom:1rem;
            height:195px;
        }
    }

    .fullscreenHeader{
        .header-event-name{
            height:100%;
        }
    }

    .quote{
        height:400px;
        .quoterow{
            height:400px;
            .col{
                blockquote{
                    font-size:3.1rem;
                }
                span{
                    font-size:1.8rem;
                    margin-top:0px;
                }
            }
        }
    }

    .findus{
        margin:3rem;
        .col-12{
            img{
                width:300px;
                top:-115px;
                right:50px;
            }
        }
    }

    .fullwidth{
        &.black{
            &.hashevents{
                h3{
                    font-size:2.8rem;
                }
            }
            &.wannabook{
                h3{
                    font-size:2.8rem;
                }
            }

        }
    }

}

@media only screen and (max-width: 768px) {

    div.share {
        bottom: 26px !important;
        left: 42px !important;
    }

    .maplinks{
        a{
            width:100%;
            margin-left:0px !important;
            margin-bottom:1rem;
        }
    }


    .careersImages{
        .entry{
            padding:5px;
        }
    }

    .eventinfo{
        a.btn{
            margin:0px 0px 10px 0px !important;
            width:100%;
        }
    }

    .mobilehide{display:none !important;}
    .requesttext{
        .textarea{
            p{
                margin-top:auto;
            }
        }
    }

    .upcomingevents {
        .toggler {
            display:none;
        }
    }



    .eventscalendar {
        .wrap {
            padding:20px;
        }
    }

    .fullscreenHeader{
        .header-event-name-name{
            &.singlepage{
                font-size:8rem !important;
                &.smaller{
                    font-size:5rem !important;
                    line-height:4.3rem;
                }
            }
            &.smallerMobile{
                font-size: 6rem !important;
            }
        }
    }

.blackcol{
    &.biggerH3{
        h3 {
            font-size: 2.0rem !important;
        }
    }
}


    .fullwidth{
        &.black{
            h3{
                font-size:2.5rem;
            }
        }
    }
    .ue-all{
        text-align:right;
     }

    .slick-prev{left:-35px !important;}
    .slick-next{right:-35px !important;}

    .galleryItem{
        .cover{
            height:520px;
        }
    }

    .partners{
        padding:4rem 0;
        .col-12{
            text-align:center;
            img{
                margin-top:2rem;
            }
        }
    }

    .footer{
        img{
            margin-top:0px;
        }
        .mailinglist{
            margin-top:1.5rem;
        }
    }

    .section{
        &.news{
            margin:5rem auto;
        }
    }

    .newsbody{
        img.newsImage{
            float:right;
            width:50%;
            margin:0 0 1rem 1rem !important;
        }
    }

}

@media only screen and (max-width: 767px) {

    div.share {
        top: 0px !important;
        left: 0px !important;
        h4{
            margin-bottom: 0px !important;
        }
        a{
            margin-top:0px !important;
            top:0px;
            left:0px;
        }
    }

    .maplinks{
        a{
            width:100%;
            margin-left:0px !important;
            margin-bottom:1rem;
        }
        &.contactones {
            margin-top: 3rem;
        }
    }




    .nestedCalendar{
        .wrap{
            min-height:auto;
        }
    }

    .galleryentry{
        float:left;
        height:auto;
        margin-bottom:1rem !important;
    }

    .careersImages{
        margin-top:1rem;
        .entry{
            padding:15px;
        }
    }

    .eventinfo {
        .col{
            &:nth-of-type(1){
                padding-bottom:2rem;
            }
        }
    }
    #eventsList {
        .eventscalendar {
            display: none;
        }
    }




        .upcomingevents{
        .toggler{
            display:block;
            width:100%;
            margin:-2rem auto 2rem auto !important;
            &.gallerytoggler{
                margin-top:2rem !important;
            }
        }
    }

    .header__logoWrap{
        right:-4rem;
    }
    .header__menuList--secondary{
        margin-top: auto;
    }
    .mailinglist{
        margin-top:2rem;
    }
    .mailinglist form input[type="text"]{
        width:100%;
        float:none;
        text-align:center;
        border-bottom:1px solid $common-lightgray;
    }
    .mailinglist form input[type="submit"]{
        width:100%;
        float:none;
        border:none;
    }
    .socials {
        margin-top: 20px;
    }

    .fullscreenHeader{
        height:100%;
        min-height:600px;
        .header-event-name{
            height:712px;
            .header-event-name-name{
                font-size: 3.5rem;
                line-height: 3.5rem;
                &.singlepage{
                    font-size: 4.5rem !important;
                    &.smaller{
                        font-size:4rem !important;
                        line-height:4.3rem;
                    }
                }
                &.smallerMobile{
                    font-size: 2.5rem !important;
                }
            }
            .header-event-name-links{
                a{
                   &:nth-of-type(2){
                       margin-left:-5px;
                   }
                }
            }

            .header-event-name-date{
                font-size: 2rem;
            }
        }
    }

    .textarea{
        h3{
            font-size:2.2rem;
            a{
                clear:both;
                width:100%;
                margin:1rem auto !important;
            }
        }
    }

    .newsbody{
        img.newsImage{
            float:none !important;
            width:100% !important;
            margin:0px 0px 1rem 0px !important;
        }
    }

    .eventsgrid{
        margin-bottom:2rem;
    }

    .upcomingEventEntry{
        .ue-artistname, .ue-eventname{
            min-height: auto !important;
        }
        &.artistEntry{
            text-align:left !important;
            .socials{
                a{
                    width:30px !important;
                }
            }
        }
    }

    .news{
        margin:4rem auto !important;
        .btn{
            &.wynwood{
                font-size:1.1rem;
            }
        }
        h3{
           margin-bottom:0rem;
        }
    }

    .fullwidth{
        &.black{
            h3{
                font-size:1.8rem;
                text-align:center;
            }
            .col-12{
                text-align:center;
                a{
                    margin-top:1rem;
                    width:100%;
                }
            }
            &.hashevents{
                h3{
                    font-size:1.8rem;
                }
            }
            &.wannabook{
                h3{
                    font-size:1.8rem;
                }
            }
       }
    }
    .upcomingEventEntry{
        img, .fader{
            height:172px;
        }
        .smallCover{
            margin-bottom:1rem;
            height:195px;
        }

        a{
            width:100%;
        }
    }

    .bigmargin, .section{
        margin:2rem 0 2rem 0;
    }

    .ue-all{
        text-align:center;
    }

    .slick-prev, .slick-next{
        display:none !important;
    }

    .galleryItem{
        .cover{
            height:420px;
            a{
                position:absolute;
                width:94%;
                text-align:center;
                bottom:3%;
                left:3%;
            }

            div.info{
                position:absolute;
                text-align:center;
                bottom:65px;
                left:3%;
                width:94%;
            }
        }
    }

    .news{
        margin:3rem auto;
    }

    .findus{
        .col-12{
            h3, h4{
                text-align:center;
            }
            img{
                position:relative;
                top:auto;
                right:auto;
                width:100%;
                margin-top:1.5rem;
            }
        }
    }

.quote{
    blockquote{
        font-size:2.5rem !important;
    }
    span{
        font-size:1.4rem !important;
        margin-top:-1rem !important;
    }
}

    .partners{
        img{
            width:100%;
        }
        margin-bottom:0px;
    }

    .footer{
        .links{
            font-size:1rem;
        }
      .socials{
          text-align:center;
        a{
          margin-bottom:0.5rem;
        }
      }
    }

    .blackcol {
        &.biggerH3 {
            margin-top:3rem;
        }
    }

}

@media (min-width: 992px) {

    .fullwidth{
        &.black{
            &.hashevents{
                h3{
                    font-size:4rem;
                }
            }
            &.wannabook{
                h3{
                    font-size:2.6rem;
                }
            }

        }
    }

}

@media (min-width: 1200px) {
    .fullwidth{
        &.black{
            &.hashevents{
                h3{
                    font-size:5rem;
                }
            }
            &.wannabook{
                h3{
                    font-size:3.5rem;
                }
            }

        }
    }

}

@media (min-width: 1500px) {
    .fullwidth{
        &.black{
            &.hashevents{
                h3{
                    font-size:7.6rem;
                }
            }
            &.wannabook{
                h3{
                    font-size:4.8rem;
                }
            }

        }
    }


}
